import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, runInAction } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import Document from "~/models/Document";
import Meeting from "~/models/Meeting";

import { UserPermission } from "~/types";
import { client } from "~/utils/ApiClient";
import { rrulestr } from "rrule";

interface FormattedMeeting {
  id: string;
  title: string;
  startingDate: Date;
  duration: string;
}

export default class MeetingsStore extends BaseStore<Meeting> {
  constructor(rootStore: RootStore) {
    super(rootStore, Meeting);
  }

  @action
  fetchFromDoc = async (id: string): Promise<Meeting[]> => {
    this.isFetching = true;
    const options = { documentId: id };
    try {
      const res = await client.post(`/meetings.list`, options);
      invariant(res && res.data, "Meetings list not available");
      runInAction("MeetingStore#fetchNamedPage", () => {
        res.data.forEach((element) => {
          element.documentId = id;
        });
        res.data.forEach(this.add);
        this.addPolicies(res.policies);
        this.isLoaded = true;
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  fetchFromUser = async (id: string): Promise<Meeting[]> => {
    this.isFetching = true;
    const options = { userId: id };
    try {
      const res = await client.post(`/meetings.list`, options);
      invariant(res && res.data, "Meetings list not available");
      runInAction("MeetingStore#fetchNamedPage", () => {
        res.data.forEach((element) => {
          if (element.documents.length > 0) {
            element.documentId = element.documents[0].id;
          }
        });
        res.data.forEach(this.add);
        this.addPolicies(res.policies);
        this.isLoaded = true;
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  fetchParticipants = async (id: string): Promise<string[]> => {
    this.isFetching = true;
    const options = { meetingId: id };
    try {
      const res = await client.post(`/meetings.participantsemail`, options);
      // invariant(res && res.data, "Meetings participants not available");
      if (res && res.data) {
        return res.data;
      } else {
        return [];
      }
    } catch {
      return [];
    } finally {
      this.isFetching = false;
    }
  };

  @action
  fetchParticipantsFiltered = async (id: string): Promise<any> => {
    this.isFetching = true;
    const options = { meetingId: id };
    try {
      const res = await client.post(`/meetings.participantsfiltered`, options);
      // invariant(res && res.data, "Meetings participants not available");
      if (res && res.data) {
        return res.data;
      } else {
        return [];
      }
    } catch {
      return [];
    } finally {
      this.isFetching = false;
    }
  };

  @action
  linkMeetingAndDocument = async ({
    documentId,
    meetingId,
  }: {
    documentId: string;
    meetingId: string;
  }): Promise<any> => {
    const options = {
      documentId: documentId,
      meetingId: meetingId,
    };
    try {
      const res = await client.post(`/meeting.linkDocument`, options);
      invariant(res && res.data, "Error during meeting creation");
      return res.data;
    } catch (err) {
      return err;
    }
  };

  @action
  reportEmail = async (
    document: Document,
    emails: string[],
    personnalMessage: string
  ) => {
    return await client.post("/meetings.email", {
      documentId: document.id,
      emails,
      personnalMessage,
    });
  };

  @action
  unlinkDocumentAndMeeting = async ({
    meetingId,
  }: {
    meetingId: string;
  }): Promise<any> => {
    const options = {
      meetingId: meetingId,
    };
    try {
      const res = await client.post(`/meeting.unlink`, options);
      invariant(res && res.data, "Error during meeting deletion");
      return res.data;
    } catch (err) {
      return err;
    }
  };

  @action
  createMeeting = async ({
    title,
    startingDate,
    endingDate,
    attendees,
    documentId,
  }: {
    title: string;
    startingDate: string;
    endingDate: string;
    attendees: Array<UserPermission>;
    documentId: string;
  }): Promise<any> => {
    const options = {
      title: title,
      startingDate: startingDate,
      endingDate: endingDate,
      attendees: attendees,
      documentId: documentId,
    };
    try {
      const res = await client.post(`/meetings.create`, options);
      invariant(res && res.data, "Error during meeting creation");
      res.data.meeting.documentId = documentId;
      this.add(res.data.meeting);
      return res.data;
    } catch (err) {
      return err;
    }
  };

  @action
  deleteMeeting = async (id: string): Promise<any> => {
    const options = {
      meetingId: id,
    };
    try {
      const res = await client.post(`/meetings.delete`, options);
      invariant(res && res.ok, "Error during meeting deletion");
      const meeting = this.get(id);
      if (meeting) {
        this.remove(id);
      }
      return res.data;
    } catch (err) {
      return err;
    }
  };

  @action
  updateMeeting = async ({
    title,
    startingDate,
    endingDate,
    attendees,
    documentId,
    meetingId,
  }: {
    title: string;
    startingDate: string;
    endingDate: string;
    attendees: Array<UserPermission>;
    documentId: string;
    meetingId: string;
  }): Promise<any> => {
    const options = {
      title: title,
      startingDate: startingDate,
      endingDate: endingDate,
      attendees: attendees,
      documentId: documentId,
      meetingId: meetingId,
    };
    try {
      const res = await client.post(`/meetings.update`, options);
      invariant(res && res.data, "Error during meeting creation");
      this.remove(meetingId);
      res.data.meeting.documentId = documentId;
      this.add(res.data.meeting);
      return res.data;
    } catch (err) {
      return err;
    }
  };

  @computed
  get all() {
    return orderBy(Array.from(this.data.values()), "startingDate", "asc");
  }

  allWithRecurrence(start: Date, end: Date): Array<Meeting> {
    const instances = [...this.all];
    const recurrentMeetings = this.all.filter(
      (m) => m.recurrence && m.recurrence !== "" && m.recurrence !== "instance"
    );

    for (const meeting of recurrentMeetings) {
      const meetingStart = new Date(meeting.startingDate);
      const meetingEnd = new Date(meeting.endingDate);
      const rule = rrulestr(meeting.recurrence);
      rule.between(start, end).forEach((date) => {
        const startingDate = new Date(date);
        startingDate.setHours(meetingStart.getHours());
        startingDate.setMinutes(meetingStart.getMinutes());
        startingDate.setSeconds(meetingStart.getSeconds());

        const difference = meetingEnd.getTime() - meetingStart.getTime();
        const endingDate = new Date(startingDate.getTime() + difference);

        if (startingDate > meetingStart) {
          const newMeeting: any = {
            id: meeting.id,
            title: meeting.title,
            startingDate: startingDate,
            endingDate: endingDate,
            isAllDay: meeting.isAllDay,
            recurrence: "instance",
            documentId: meeting.documentId ? meeting.documentId : null,
            numberOfParticipants: meeting.numberOfParticipants,
          };
          instances.push(newMeeting);
        }
      });
    }

    return instances;
  }

  @computed
  get allNext() {
    return orderBy(
      Array.from(this.data.values()),
      "startingDate",
      "asc"
    ).filter((m) => new Date(m.startingDate) > new Date());
  }

  @computed
  get allToday() {
    return orderBy(
      Array.from(this.data.values()),
      "startingDate",
      "asc"
    ).filter(
      (m) =>
        (new Date(m.startingDate).toDateString() ===
          new Date().toDateString() ||
          new Date(m.endingDate).toDateString() ===
            new Date().toDateString()) &&
        new Date(m.endingDate) > new Date()
    );
  }

  allByDocument(documentId: string) {
    let meetingsFormatted = {
      past: new Array<FormattedMeeting>(),
      next: new Array<FormattedMeeting>(),
    };
    // const meetings = orderBy(
    //   Array.from(this.data.values()),
    //   "startingDate",
    //   "asc"
    // );
    if (this.all.length > 0) {
      this.all.forEach((meeting) => {
        if (meeting.documentId !== documentId) {
          return null;
        }
        const startingDateFormatted = Date.parse(meeting.startingDate);
        const endingDateFormatted = Date.parse(meeting.endingDate);
        let minutes = Math.floor(
          (endingDateFormatted - startingDateFormatted) / 60000
        );
        const hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        const duration =
          hours !== 0
            ? hours.toString() +
              " h" +
              (minutes !== 0 ? " " + minutes.toString() : "")
            : minutes.toString() + " min";
        const meetingDetails: FormattedMeeting = {
          title: meeting.title,
          startingDate: new Date(meeting.startingDate),
          duration: duration,
          id: meeting.id,
        };
        if (startingDateFormatted > Date.now()) {
          meetingsFormatted.next.push(meetingDetails);
        } else {
          meetingsFormatted.past.push(meetingDetails);
        }
      });
    }
    return meetingsFormatted;
  }
}
