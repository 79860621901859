import { observable } from "mobx";
import BaseModel from "~/models/BaseModel";
import { NavigationNode, TeamPublicInfos, UserPublicInfos } from "~/types";
import Field from "./decorators/Field";

export default class Task extends BaseModel {
  @observable
  isSaving: boolean;

  @observable
  isLoaded: boolean;

  @Field
  @observable
  id: string;

  @Field
  @observable
  title: string;

  @Field
  @observable
  statut: string;

  @Field
  @observable
  dueDate: string;

  @Field
  @observable
  details: string;

  document: NavigationNode;

  users: UserPublicInfos[];

  team: TeamPublicInfos;

  createdBy: UserPublicInfos;

  createdAt: string;

  updatedAt: string;
}
