import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";

export default createGlobalStyle`
  ${styledNormalize}

  * {
    box-sizing: border-box;
  }

  html,
  body {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  body,
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  body {
    font-size: 16px;
    line-height: 1.5;
    color: ${(props) => props.theme.textBox};
    overscroll-behavior-y: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  @media (min-width: ${(props) =>
    props.theme.breakpoints.tablet}px) and (display-mode: standalone) {
    body:after {
      content: "";
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: ${(props) => props.theme.titleBarDivider};
      z-index: ${(props) => props.theme.depths.titleBarDivider};
    }
  }

  a {
    color: ${(props) => props.theme.link};
    text-decoration: none;
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.25;
    margin-top: 1em;
    margin-bottom: 0.5em;
  }
  h1 { font-size: 2.25em; }
  h2 { font-size: 1.5em; }
  h3 { font-size: 1.25em; }
  h4 { font-size: 1em; }
  h5 { font-size: 0.875em; }
  h6 { font-size: 0.75em; }

  p,
  dl,
  ol,
  ul,
  pre,
  blockquote {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  hr {
    border: 0;
    height: 0;
    border-top: 1px solid ${(props) => props.theme.divider};
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  .js-focus-visible .focus-visible {
    outline-color: ${(props) => props.theme.primary};
  }

  .testVideoDiv {
    position: relative;
    vertical-align: middle;
    align-self: center;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    box-shadow: var(--shadow-dark);
    background: #fff;
  }

  .testVideoDiv video {
    position: absolute;
    right: 0;
    object-fit: cover;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #000;
    border-radius: 10px;
    overflow: hidden;
    left: 0;
    top: 0;
    background-size: cover;
    overflow: hidden;
    -webkit-transition: margin-top 1s ease-in-out;
    -moz-transition: margin-top 1s ease-in-out;
    -o-transition: margin-top 1s ease-in-out;
    transition: margin-top 1s ease-in-out;
  }

  .rbc-event.linked {
    background-color: #7558f9 !important;
  }

  .rbc-event.not-linked {
    background-color: lightgray !important;
  }

  .rbc-event {
    background-color: ${(props) => props.theme.primary} !important;
    border: 1px solid white !important;
  }

  .rbc-today {
    background-color: white !important;
  }

  .rbc-show-more {
    padding: 2px 5px;
    border-radius: 3px;
  }

  #fairwai-dark {
    .react-calendar {
      background-color: lightgray !important;
    }

    .react-calendar__navigation > button {
      color: black !important;
    }

    .react-calendar__tile react-calendar__month-view__days__day {
      color: black !important;
    }

  }

  #fairwai-calendar-dark {
    .rbc-toolbar button {
      color: white !important;
      border: 1px solid #292C2F !important;
    }

    .rbc-show-more {
      background-color: lightgray !important; 
    }

    .rbc-toolbar button:hover {
      color: #373a3c !important;
    }

    .rbc-event-label, .rbc-event-content {
      color: black !important;
    }

    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      color: black !important;
    }

    .rbc-current button {
      color: black !important;
    }

    .rbc-month-view {
      border: 1px solid #292C2F !important;
    }

    .rbc-time-view {
      border: 1px solid #292C2F !important;
    }

    .rbc-time-header-content {
      border-left: 1px solid #292C2F !important;
    }

    .rbc-header + .rbc-header {
      border-left: 1px solid #292C2F !important;
    }

    .rbc-header {
      border-bottom: 1px solid #292C2F !important;
    }

    .rbc-time-content {
      border-top: 1px solid #292C2F !important;
    }

    .rbc-today {
      color: black !important;
      background-color: #527784 !important;
    }

    .rbc-day-bg + .rbc-day-bg {
      border-left: 1px solid #292C2F !important;
    }

    .rbc-month-row + .rbc-month-row {
      border-top: 1px solid #292C2F !important;
    }
    
    .rbc-time-content > * + * > * {
      border-left: 1px solid #292C2F !important;
    }

    .rbc-timeslot-group {
      border-bottom: 1px solid #292C2F !important;
    }

    .rbc-day-slot .rbc-time-slot {
      border-top: 1px solid #292C2F !important;
    }
  }
`;
