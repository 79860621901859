import { FunctionComponent } from "enzyme";
import React, { createContext, useContext, useMemo } from "react";
import { Step } from "react-joyride";
import { useSetState } from "react-use";

export interface AppState {
  run: boolean;
  stepIndex: number;
  steps: Step[];
  tourActive: boolean;
  eventDetailsOpen: boolean;
  eventUptdateOpen: boolean;
  documentRedirection: string;
}

const defaultState = {
  run: false,
  stepIndex: 0,
  steps: [],
  tourActive: false,
  eventDetailsOpen: false,
  eventUptdateOpen: false,
  documentRedirection: "",
};

interface JoyrideProviderProps {
  children: React.ReactNode;
}

export const JoyrideContext = createContext({
  joyrideState: defaultState,
  setJoyrideState: () => undefined,
});
JoyrideContext.displayName = "JoyrideContext";

export const JoyrideProvider: FunctionComponent<JoyrideProviderProps> = ({
  children,
}) => {
  const [joyrideState, setJoyrideState] = useSetState(defaultState);

  const value = useMemo(
    () => ({
      joyrideState,
      setJoyrideState,
    }),
    [joyrideState, setJoyrideState]
  );

  return (
    <JoyrideContext.Provider value={value}>{children}</JoyrideContext.Provider>
  );
};

export const useJoyrideContext = (): {
  setJoyrideState: (
    patch: Partial<AppState> | ((previousState: AppState) => Partial<AppState>)
  ) => void;
  joyrideState: AppState;
} => {
  const context = useContext(JoyrideContext);

  if (!context) {
    throw new Error("useAppContext must be used within a AppProvider");
  }

  return context;
};
