import { observable } from "mobx";
import BaseModel from "~/models/BaseModel";
import { client } from "~/utils/ApiClient";
import Field from "./decorators/Field";

export default class Meeting extends BaseModel {
  @observable
  isSaving: boolean;

  @observable
  isLoaded: boolean;

  @Field
  @observable
  id: string;

  @observable
  documentId: string;

  @observable
  documentTitle: string;

  @observable
  documentUrl: string;

  @Field
  @observable
  meetingCalendarId: string;

  @Field
  @observable
  isAllDay: boolean;

  @Field
  @observable
  meetingOutlookId: string;

  @Field
  @observable
  iCalUId: string;

  @Field
  @observable
  recurrence: string;

  @Field
  @observable
  numberOfParticipants: number;

  @Field
  @observable
  title: string;

  @Field
  @observable
  startingDate: string;

  @Field
  @observable
  endingDate: string;

  createdAt: string;

  updatedAt: string;

  createdById: string;

  deletedAt: string | null | undefined;

  export = () => {
    return client.get("/meetings.export", {
      id: this.id,
    });
  };
}
