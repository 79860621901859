import { computed } from "mobx";
import BaseModel from "./BaseModel";

class DocumentMembership extends BaseModel {
  id: string;

  userId: string;

  documentId: string;

  permission: string;

  @computed
  get isEditor(): boolean {
    return this.permission === "read_write";
  }

  @computed
  get isViewer(): boolean {
    return this.permission === "read";
  }
}

export default DocumentMembership;
