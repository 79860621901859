import { observable } from "mobx";
import BaseModel from "~/models/BaseModel";
import { MentionUser } from "~/types";
import Field from "./decorators/Field";

export default class Notification extends BaseModel {
  @observable
  isSaving: boolean;

  @observable
  isLoaded: boolean;

  @Field
  @observable
  id: string;

  @Field
  @observable
  type: string;

  @observable
  documentId: string;

  @Field
  @observable
  userId: string;

  @Field
  @observable
  createdById: string;

  @Field
  @observable
  isOpened: boolean;

  @Field
  @observable
  isClicked: boolean;

  createdBy: MentionUser;

  document: any;

  createdAt: string;
}
