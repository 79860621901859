import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, runInAction } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import Document from "~/models/Document";
import Meeting from "~/models/Meeting";
import Notification from "~/models/Notification";
import { client } from "~/utils/ApiClient";
import { type } from "os";
import { PaginationParams } from "~/types";

export default class NotificationsStore extends BaseStore<Notification> {
  constructor(rootStore: RootStore) {
    super(rootStore, Notification);
  }

  @action
  fetchFromUser = async (
    options: PaginationParams
  ): Promise<Notification[]> => {
    this.isFetching = true;
    try {
      const res = await client.post(`/notifications.list`, options);
      invariant(res && res.data, "notifications list not available");
      runInAction("MeetingStore#fetchNamedPage", () => {
        res.data.forEach(this.add);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  setOpened = async (): Promise<boolean> => {
    const res = await client.post(`/notifications.setopened`);
    invariant(res && res.data, "notifications list not updated");
    return res.data;
  };

  @action
  setCliked = async (notificationId: string): Promise<boolean> => {
    const options = { notificationId };
    const res = await client.post(`/notifications.setclicked`, options);
    invariant(res && res.data, "notifications click not updated");
    return res.data;
  };

  @action
  createMention = async (
    userId: string,
    documentId: string,
    type: string
  ): Promise<Notification> => {
    this.isFetching = true;
    const options = { userId: userId, documentId: documentId, type: type };
    try {
      const res = await client.post(`/notifications.createmention`, options);
      invariant(res && res.data, "Error during creation mention");
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @computed
  get all() {
    return orderBy(Array.from(this.data.values()), "createdAt", "desc");
  }
}
