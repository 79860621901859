import invariant from "invariant";
import { orderBy } from "lodash";
import { action, computed, runInAction } from "mobx";
import BaseStore from "~/stores/BaseStore";
import RootStore from "~/stores/RootStore";
import Notification from "~/models/Notification";
import { client } from "~/utils/ApiClient";
import Task from "~/models/Task";
import { PaginationParams } from "~/types";

export default class TasksStore extends BaseStore<Task> {
  constructor(rootStore: RootStore) {
    super(rootStore, Task);
  }

  @action
  fetchSingleTask = async (taskId: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
    };
    try {
      const res = await client.post(`/tasks.getsingletask`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  fetchFromDocument = async (documentId: string): Promise<Task[]> => {
    this.isFetching = true;
    const options = {
      documentId,
    };
    try {
      const res = await client.post(`/tasks.list`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        res.data.forEach(this.add);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  fetchFromUser = async (
    options: PaginationParams
  ): Promise<{
    done: Task[];
    doing: Task[];
    todo: Task[];
    doneSize: number;
    doingSize: number;
    todoSize: number;
  }> => {
    this.isFetching = true;
    try {
      const res = await client.post(`/tasks.listfromuser`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        res.data.todo.forEach(this.add);
        res.data.done.forEach(this.add);
        res.data.doing.forEach(this.add);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  createTask = async (
    title: string,
    dueDate: Date,
    userIds: Array<string>,
    documentId: string | undefined
  ): Promise<Task> => {
    this.isFetching = true;
    const options = {
      title,
      dueDate,
      userIds,
      documentId,
    };
    try {
      const res = await client.post(`/tasks.create`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  addAssignedUser = async (userId: string, taskId: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      userId,
    };
    try {
      const res = await client.post(`/tasks.adduser`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      console.log(res.data);
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  updateDueDate = async (dueDate: Date, taskId: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      dueDate,
    };
    try {
      const res = await client.post(`/tasks.updateduedate`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  updateTitle = async (title: string, taskId: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      title,
    };
    try {
      const res = await client.post(`/tasks.updateTitle`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  updateDetails = async (details: string, taskId: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      details,
    };
    try {
      const res = await client.post(`/tasks.updateDetails`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  removeAssignedUser = async (
    userId: string,
    taskId: string
  ): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      userId,
    };
    try {
      const res = await client.post(`/tasks.removeuser`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      console.log(res.data);
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  updateTask = async (
    taskId: string,
    details: string,
    title: string,
    dueDate: Date,
    userIds: Array<string>
  ): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      details,
      title,
      dueDate,
      userIds,
    };
    try {
      const res = await client.post(`/tasks.update`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  updateStatut = async (taskId: string, statut: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
      statut,
    };
    try {
      const res = await client.post(`/tasks.updatestatut`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        this.add(res.data);
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  deleteTask = async (taskId: string): Promise<Task> => {
    this.isFetching = true;
    const options = {
      taskId,
    };
    try {
      const res = await client.post(`/tasks.delete`, options);
      invariant(res && res.data, "Error during creation mention");
      runInAction("TasksStore#createTask", () => {
        if (res.data) {
          this.remove(taskId);
        }
      });
      return res.data;
    } finally {
      this.isFetching = false;
    }
  };

  @computed
  get all() {
    return orderBy(Array.from(this.data.values()), "createdAt", "desc");
  }
}
