import invariant from "invariant";
import { action, runInAction } from "mobx";
import DocumentMembership from "~/models/DocumentMembership";
import Membership from "~/models/Membership";
import { PaginationParams, UserPermission } from "~/types";
import { client } from "~/utils/ApiClient";
import BaseStore, { RPCAction } from "./BaseStore";
import RootStore from "./RootStore";

export default class DocumentMembershipsStore extends BaseStore<DocumentMembership> {
  actions = [RPCAction.Create, RPCAction.Delete];

  constructor(rootStore: RootStore) {
    super(rootStore, DocumentMembership);
  }

  @action
  fetchFromDoc = async (id: string): Promise<any> => {
    this.isFetching = true;
    const options = { documentId: id };
    try {
      const res = await client.post(`/documents.memberships`, options);
      invariant(res && res.data, "Data not available");
      runInAction(`/collections.memberships`, () => {
        res.data.users.forEach(this.rootStore.users.add);
        res.data.memberships.forEach(this.add);
        this.isLoaded = true;
      });
      return res.data.users;
    } finally {
      this.isFetching = false;
    }
  };

  @action
  addDocumentPermission = async (
    userList: Array<UserPermission>,
    permission: string,
    documentId: string
  ): Promise<any> => {
    const res = await client.post(`/documents.add_user`, {
      userList,
      permission,
      documentId,
    });
    await runInAction(`/documents.add_user`, () => {
      res.data.users.forEach(this.rootStore.users.add);
      res.data.memberships.forEach(this.add);
      this.isLoaded = true;
    });
    return res.data.users;
  };

  @action
  updateDocumentPermission = async (
    userId: string,
    permission: string,
    documentId: string
  ): Promise<any> => {
    const res = await client.post(`/documents.update_user`, {
      userId,
      permission,
      documentId,
    });
    if (res.data.membership) {
      await runInAction(`/documents.update_user`, () => {
        this.remove(`${userId}-${documentId}`);
        res.data.memberships.forEach(this.add);
        this.isLoaded = true;
      });
    }

    return res.ok;
  };

  @action
  removeDocumentPermission = async (
    userId: string,
    documentId: string
  ): Promise<any> => {
    const res = await client.post(`/documents.remove_user`, {
      userId,
      documentId,
    });
    if (res.data.ok) {
      this.remove(`${userId}-${documentId}`);
    }
    return res.data.ok;
  };
}
