import JitsiMeetJS, { CreateLocalTracksOptions } from "@leandre/lib-jitsi-meet";
import JitsiLocalTrack from "@leandre/lib-jitsi-meet/dist/esm/modules/RTC/JitsiLocalTrack";
import JitsiConference from "@leandre/lib-jitsi-meet/JitsiConference";
import { bool } from "aws-sdk/clients/signer";
import _ from "lodash";
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { Participant } from "~/scenes/Meeting/utils/Participant";

type JoinMeetingProps = {
  roomName: string;
  enableCamera: bool;
  enableMicrophone: bool;
  audioInputDevice?: MediaDeviceInfo;
  audioOutputDevice?: MediaDeviceInfo;
  videoDevice?: MediaDeviceInfo;
};

export interface MeetingContextInterface {
  isInMeeting: boolean;
  room?: JitsiConference | null;
  roomName?: string;
  isSharingScreen?: boolean;
  isFullScreen?: boolean;
  isCameraEnabledAtJoin: boolean;
  isMicrophoneEnabledAtJoin: boolean;
  participants: {};
  dispatchParticipants?: any;
  dominantSpeaker?: string | null;
  localParticipant?: Participant | null;
  participantScreenSharingId?: string | null;
  maximumVisibleParticipants: number;
  setDominantSpeaker?: (speakerId: string | null) => void;
  setLocalParticipant?: (participant: Participant | null) => void;
  setParticipantScreenSharingId?: (participantId?: string | null) => void;
  setRoom?: (room: JitsiConference | null) => void;
  toggleSharingScreen?: () => void;
  toggleFullScreen?: () => void | null | undefined;
  joinMeeting?: (options: JoinMeetingProps) => void;
  createLocalAudioTrack?: () => void;
  createLocalCameraTrack?: () => void;
  createLocalDesktopTrack?: () => void;
}

const defaultState = {
  isInMeeting: false,
  isSharingScreen: false,
  isFullScreen: false,
  isCameraEnabledAtJoin: true,
  isMicrophoneEnabledAtJoin: true,
  maximumVisibleParticipants: 10,
  dominantSpeaker: null,
  participants: {},
};

// create context
export const MeetingContext =
  React.createContext<MeetingContextInterface>(defaultState);

interface MeetingProviderProps {
  children: React.ReactNode;
}

export const MeetingProvider: FunctionComponent<MeetingProviderProps> = ({
  children,
}) => {
  function joinMeeting(options: JoinMeetingProps) {
    console.log("joinMeeting", options, isFullScreen);
    window.scrollTo(0, 0);
    setRoomName(options.roomName);
    setIsInMeeting(true);
    setIsCameraEnabledAtJoin(options.enableCamera);
    setIsMicrophoneEnabledAtJoin(options.enableMicrophone);
    setAudioInputDevice(options.audioInputDevice);
    setVideoDevice(options.videoDevice);
    setAudioOutputDevice(options.audioOutputDevice);
  }

  const [roomName, setRoomName] = useState("");

  const [room, setRoom] = useState<JitsiConference | null>(null);

  const [isSharingScreen, setIsSharingScreen] = useState(
    defaultState.isSharingScreen
  );

  const [isFullScreen, setIsFullScreen] = useState(defaultState.isFullScreen);

  const [isMicrophoneEnabledAtJoin, setIsMicrophoneEnabledAtJoin] = useState(
    defaultState.isMicrophoneEnabledAtJoin
  );

  const [isCameraEnabledAtJoin, setIsCameraEnabledAtJoin] = useState(
    defaultState.isCameraEnabledAtJoin
  );

  const [audioInputDevice, setAudioInputDevice] = useState<MediaDeviceInfo>();

  const [audioOutputDevice, setAudioOutputDevice] = useState<MediaDeviceInfo>();

  const [videoDevice, setVideoDevice] = useState<MediaDeviceInfo>();

  const [maximumVisibleParticipants, setMaximumVisibleParticipants] = useState(
    defaultState.maximumVisibleParticipants
  );

  const [participantScreenSharingId, setParticipantScreenSharingId] =
    useState(null);

  const [isInMeeting, setIsInMeeting] = useState(defaultState.isInMeeting);

  const [dominantSpeaker, setDominantSpeaker] = useState<string | null>(
    defaultState.dominantSpeaker
  );

  const [localParticipant, setLocalParticipant] = useState<Participant | null>(
    null
  );

  const toggleSharingScreen = () => {
    setIsSharingScreen(!isSharingScreen);
  };

  const toggleFullScreen = () => {
    window.scrollTo(0, 0);
    setIsFullScreen(!isFullScreen);
  };

  React.useEffect(() => {
    document.body.style = isFullScreen && isInMeeting ? "overflow: hidden" : "";
  }, [isFullScreen]);

  const createLocalTrack = (
    type: string,
    options?: CreateLocalTracksOptions
  ) => {
    JitsiMeetJS.createLocalTracks({ devices: [type], ...options }).then(
      async (_localTracks: JitsiLocalTrack[]) => {
        let track = _localTracks[0];
        if (type === "desktop" && _localTracks.length > 1) {
          track = _localTracks.find((_track) => _track.isVideoTrack());
        }
        if (room) {
          try {
            await room.addTrack(track);
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  };

  const createLocalAudioTrack = () => {
    if (localParticipant?.getAudioTrack() != null) return;
    createLocalTrack("audio", {
      micDeviceId: audioInputDevice?.id,
    });
  };

  const createLocalCameraTrack = () => {
    if (localParticipant?.getCameraTrack() != null) return;
    createLocalTrack("video", {
      cameraDeviceId: videoDevice?.id,
    });
  };

  const createLocalDesktopTrack = () => {
    if (localParticipant?.getDesktopTrack() != null) return;
    createLocalTrack("desktop", {
      desktopSharingFrameRate: { min: "5", max: "15" },
    });
  };

  const dispatchParticipantsReducer = (state, action) => {
    const participantId = action.payload.participantId;
    console.log("dispatchParticipantsReducer", state, action);
    switch (action.type) {
      case "addParticipant": {
        const participant: Participant = action.payload.participant;
        let newParticipants = { ...state };
        newParticipants[participantId] = participant;
        return newParticipants;
      }
      case "removeParticipant": {
        if (participantId in state) {
          let newTracks = { ...state };
          delete newTracks[participantId];
          return newTracks;
        }
        return state;
      }
      case "addTrackToParticipant": {
        const participant: Participant = state[participantId];
        let newParticipants = { ...state };
        if (participant) {
          delete newParticipants[participantId];
          const track = action.payload.track;
          participant.addTrack(track);
          newParticipants[participantId] = participant;
        }
        return newParticipants;
      }
      case "removeTrackToParticipant": {
        const participant: Participant = state[participantId];
        let newParticipants = { ...state };
        if (participant) {
          delete newParticipants[participantId];
          const track = action.payload.track;
          participant.removeTrack(track);
          newParticipants[participantId] = participant;
        }
        return newParticipants;
      }
    }
  };

  const [participants, dispatchParticipants] = useReducer(
    dispatchParticipantsReducer,
    defaultState.participants
  );

  const generalValue = useMemo(
    () => ({
      roomName,
      room,
      setRoom,
      joinMeeting,
      isInMeeting,
      isMicrophoneEnabledAtJoin,
      isCameraEnabledAtJoin,
    }),
    [isInMeeting, room, isMicrophoneEnabledAtJoin, isCameraEnabledAtJoin]
  );

  const sharingScreenValue = useMemo(
    () => ({
      isSharingScreen,
      toggleSharingScreen,
      participantScreenSharingId,
      setParticipantScreenSharingId,
    }),
    [isSharingScreen, participantScreenSharingId]
  );

  const fullScreenValue = useMemo(
    () => ({
      isFullScreen,
      toggleFullScreen,
    }),
    [isFullScreen]
  );

  const dominantSpeakerValue = useMemo(
    () => ({
      dominantSpeaker,
      setDominantSpeaker,
    }),
    [dominantSpeaker]
  );

  const participantValue = useMemo(
    () => ({
      participants,
      dispatchParticipants,
      localParticipant,
      setLocalParticipant,
      createLocalAudioTrack,
      createLocalCameraTrack,
      createLocalDesktopTrack,
    }),
    [participants, localParticipant]
  );

  // const trackValue = useMemo(
  //   () => ({
  //     audioInputDevice,
  //     audioOutputDevice,
  //     videoDevice,
  //     setAudioInputDevice,
  //     setAudioOutputDevice,
  //     setVideoDevice,
  //   }),
  //   [audioInputDevice, audioOutputDevice, videoDevice]
  // );

  const mergedValue = {
    ...generalValue,
    ...dominantSpeakerValue,
    ...sharingScreenValue,
    ...fullScreenValue,
    ...participantValue,
    // ...trackValue,
  };
  return (
    <MeetingContext.Provider value={mergedValue}>
      {children}
    </MeetingContext.Provider>
  );
};

export const useMeeting = () => useContext(MeetingContext);
